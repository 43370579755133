import React from "react";
import "./portfolio.css";
import IMG1 from "../../assets/Amazon_Web_Services-Logo.png";
import IMG2 from "../../assets/Microsoft_Azure-Logo.png";
import IMG3 from "../../assets/Google_Cloud_Platform-Logo.png";

const data = [
  {
    id: 1,
    image: IMG1,
    title: "Amazon Web Services",
    github: "https://www.google.com",
    demo: "https://www.google.com",
    brief:
      "https://www.google.com",
  },
  {
    id: 2,
    image: IMG2,
    title: "Microsoft Azure",
    github: "https://www.google.com",
    demo: "https://www.google.com",
    brief:
      "https://www.google.com",
  },
  {
    id: 3,
    image: IMG3,
    title: "Openstack",
    github: "https://www.google.com",
    demo: "https://www.google.com",
    brief:
      "https://www.google.com",
  }
];

const Portfolio = () => {
  return (
    <section id="portfolio">
      <h5>My most recent work can be found on YouTube</h5>
      <h2>Portfolio</h2>
      <div className="container portfolio__container">
        {data.map(({ id, image, title, github, demo, brief }) => {
          return (
            <article key={id} className="portfolio__item">
              <div className="portfolio__item-image">
                <img src={image} alt={title} />
              </div>
              <h3>{title}</h3>
              <div className="portfolio__item-cta">
                <a href={github} className="btn">
                  Github
                </a>
                <a href={brief} className="btn">
                  Project Brief
                </a>
              </div>
              <div className="portfolio__item-cta">
                <a href={demo} className="btn btn-primary">
                  Live Demo
                </a>
              </div>
            </article>
          );
        })}
      </div>
    </section>
  );
};

export default Portfolio;
