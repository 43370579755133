import React from "react";
import "./about.css";
import profile_picture from "../../assets/me-about.png";
import { FaAward } from "react-icons/fa";
import { FiUsers } from "react-icons/fi";
import { VscFolderLibrary } from "react-icons/vsc";

const About = () => {
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={profile_picture} alt="About Anand image" />
          </div>
        </div>
        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className="about__icon" />
              <h5>Experience</h5>
              <small> <br />
                18 years of Cloud Experience
              </small>
            </article>
            <article className="about__card">
              <FiUsers className="about__icon" />
              <h5>Education</h5>
              <small> <br />
                B.Tech (Information Technology) <br />
                <br />
                MBA <br /> (Human Resource)
              </small>
            </article>
            <article className="about__card">
              <VscFolderLibrary className="about__icon" />
              <h5>Certifications</h5>
              <small>
                CCNA <br />
                RHCE <br />
                AWS SysOps <br />
                Openstack <br />
                Azure Devops expert <br />
                ITIL <br />
                PMP <br />
              </small>
            </article>
          </div>
          <p>
            Motivated Cloud Architect and Devops Engineer with 18 years of progressive 
            experience. Energetic self-starter and team builder. Navigates high-stress 
            situations and achieves goals on time and under budget. Dedicated in achieving 
            tangible results and cross-team collaboration. Proactive and excited to partner 
            with like-minded individuals to achieve goals.
          </p>
          <p></p>
          <a href="#contact" className="btn btn-primary">
            Let's Talk
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
